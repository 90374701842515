// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@import 'vue-select/dist/vue-select.css';
// Variables
@import 'variables';

@tailwind base;
@tailwind components;
@tailwind utilities;

.gsc-search-box {
    @apply border-2 border-primary rounded-md px-1;
}

.gsc-search-box button.gsc-search-button {
    @apply bg-primary rounded-md;
}

.gm-svpc:focus, .gm-style-mtc > button:focus {
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.day-of-travel-btn, .pick-a-date-btn, .direction-of-travel-btn {
    &:focus {
        font-weight: bold;
    }
}

*:focus {
    @apply ring-2 ring-secondary-800 outline-none;
}

h1, h2 {
    @apply font-museo
}

div.route-description {
    strong {
        a {
            font-weight: 700 !important;
        }
    }
    a {
        --tw-prose-links: rgb(187, 72, 27);
    }
    a:hover {
        @apply text-secondary-900;
    }
}

.project-entry {
    div[type="section"] {
        @apply border-primary-gray;

        div.section-header {
            @apply bg-primary-gray
        }
    }
}

.page-entry {
    .prose a {
        @apply text-secondary underline;
    }

    .tabs > div.card {

    }

    .tabs > div.grid-of-cards {
        div.sets\.CustomHtml, div[type="text"] {
            @apply bg-white p-3 rounded-xl;
        }
        div.group-of-cards h2.group-title  {
            @apply text-white;
        }
    }

    .tabs > div.default {
        @apply text-white;

        h2, h3, h4, h5, h6 {
            color: #fff;
        }
    }
}

.page-entry .with-tab-list p, .page-entry .with-tab-list h2, .page-entry .with-tab-list h3, .page-entry .with-tab-list h4, .page-entry .with-tab-list :where(strong):not(:where([class~="not-prose"] *)) {
    @apply text-gray-800
}

.link {
    @apply text-secondary underline;

    &:hover {
        @apply text-secondary-darker underline;
    }

    &--button {
        @apply inline-flex items-center;
        @apply py-2 px-4;
        @apply rounded;
        @apply text-white;
        @apply bg-secondary no-underline;

        &:hover {
            @apply text-white bg-secondary-darker;
            text-decoration: none;
        }

        &:focus {
            @apply text-white bg-secondary-darker ring-white ring-inset ring-offset-4 ring-offset-secondary-darker ring-2;
            text-decoration: none;
        }
    }
}

.webform {
    @apply mt-12 sm:mt-6;
    p {
        @apply mb-8;
    }

    h1 {
        font-size: 2.5rem;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    a {
        color: #BB481B;
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: numeric;
    }

    ul, ol {
        margin-left: 0.7rem;
        padding-bottom: 0.5rem;
    }

    .field,
    select:not(.field-custom),
    input[type="text"]:not(.field-custom),
    input[type="tel"]:not(.field-custom),
    input[type="number"]:not(.field-custom),
    input[type="email"]:not(.field-custom),
    input[type="date"]:not(.field-custom),
    input[type="time"]:not(.field-custom) {
        @apply
            px-4
            py-2
            mt-1
            block
            w-full
            rounded-md
            border
            border-gray-700
            shadow-sm
            focus:border-primary-500 focus:ring-2 focus:ring-primary-300;
    }

    .field-in-group {
        @apply block w-full min-w-0 flex-1 px-3 py-2 focus:outline-none focus:ring-0 sm:text-sm;
    }
    .field-in-group--prepend {
        @apply rounded-r-md;
    }
    .field-in-group--append {
        @apply rounded-l-md;
    }

    input[readonly],
    select[readonly],
    textarea[readonly],
    .field-in-group[readonly] {
        @apply bg-gray-50;
    }

    textarea:not(.field-custom) {
        @apply
            mt-1
            block
            w-full
            rounded-md
            border
            border-gray-700
            shadow-sm
            focus:border-primary-500 focus:ring-2 focus:ring-primary-300
    }
    input[type="text"].hidden {
        display: none;
    }
}

.google-translate-element {
    div {
        @apply flex items-center gap-3 flex-wrap;

        select {
            @apply bg-white border border-primary-gray-darker rounded shadow-sm py-2 focus:outline-none focus:ring-secondary focus:border-secondary;
        }
    }
}

.gsc-input-box {
    a {
       color: #591769 !important;
    }
}

.gscb_a {
    color: #591769 !important;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.blink-left {
    -webkit-animation: blink-left 2s infinite both;
    -moz-animation: blink-left 2s infinite both;
    animation: blink-left 2s infinite both;
}

.blink-right {
    -webkit-animation: blink-right 2s infinite both;
    -moz-animation: blink-right 2s infinite both;
    animation: blink-right 2s infinite both;
}

.blink-top {
    -webkit-animation: blink-top 2s infinite both;
    -moz-animation: blink-top 2s infinite both;
    animation: blink-top 2s infinite both;
}

.blink-bottom {
    -webkit-animation: blink-bottom 2s infinite both;
    -moz-animation: blink-bottom 2s infinite both;
    animation: blink-bottom 2s infinite both;
}

.mapdiv {
    button {
        &:focus {
            @apply ring-0;
            outline: 2px solid rgb(68, 68, 68);
            outline-offset: 2px;
        }
    }

    .gm-ui-hover-effect {
        &:focus {
            @apply ring-secondary ring-inset rounded-lg;
        }
    }

    .gm-iv-close,
    .gm-iv-address a {
        &:focus {
            @apply ring-0;
            outline: 2px solid white;
            outline-offset: 2px;
        }
    }

    .gm-style-cc {
        height: 26px !important;

        button, a, span {
            display: inline-flex;
            height: 26px;
            line-height: 26px;
        }

        button, a {
            &:focus {
                @apply ring-0 outline-2 outline-offset-2 outline-secondary;
            }
        }
    }
}

.gm-fullscreen-control {
    &:focus, &:hover {
        outline: 2px solid rgb(68, 68, 68) !important;
        outline-offset: 2px !important;
    }
}

.vc-pane-container,
.vc-popover-content-wrapper {
    button,
    [role=button] {
        &:focus {
            @apply ring-0 outline outline-2 outline-offset-2 outline-secondary-800;
        }
    }

    .vc-highlight-content-solid {
        &:focus {
            @apply ring-0 ring-transparent outline outline-offset-2 outline-secondary-800;
        }
    }
}

.vc-popover-content-wrapper {
    z-index: 100 !important;
}

.vc-nav-title {
    margin: 0 !important;
    display: inline-block !important;
}

.vc-popover-content .vc-popover-content-wrapper {
    z-index: 51;
}

.vc-popover-content .vc-popover-content-wrapper {
    z-index: 51;
}

@-webkit-keyframes blink-left {
    0% {
        transform: translateX(0px);
        opacity: 1;
    }
    50% {
        transform: translateX(4px);
        opacity: 0.5;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}
@keyframes blink-left {
    0% {
        transform: translateX(0px);
        opacity: 1;
    }
    50% {
        transform: translateX(4px);
        opacity: 0.5;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@-webkit-keyframes blink-right {
    0% {
        transform: translateX(0px);
        opacity: 1;
    }
    50% {
        transform: translateX(-4px);
        opacity: 0.5;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}
@keyframes blink-right {
    0% {
        transform: translateX(0px);
        opacity: 1;
    }
    50% {
        transform: translateX(-4px);
        opacity: 0.5;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@-webkit-keyframes blink-top {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }
    50% {
        transform: translateY(4px);
        opacity: 0.5;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
@keyframes blink-top {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }
    50% {
        transform: translateY(4px);
        opacity: 0.5;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@-webkit-keyframes blink-bottom {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }
    50% {
        transform: translateY(-4px);
        opacity: 0.5;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
@keyframes blink-bottom {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }
    50% {
        transform: translateY(-4px);
        opacity: 0.5;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.prose {
    /*
     * Default prose color for bold items is black, but it does not respect colors
     * that editors may have chosen in Bard editors in Statamic control panel.
     */
    --tw-prose-bold: inherit;
    --tw-prose-body: inherit;
}

.form-input, .form-textarea, .form-select, .form-multiselect {
    @apply ring-0 ring-transparent border-gray-600 rounded;

    &:focus {
        @apply border-secondary ring-0 outline-2 outline-offset-2 outline-secondary;
    }

    &.on-primary-color {
        &:focus {
            @apply border-white outline-white;
        }
    }
}
